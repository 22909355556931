<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="#"
            class="d-flex align-center"
          >
            <v-row class="text-center">
              <v-col
                class="d-flex justify-center"
                cols="12"
              >
                <v-img
                  :src="`${this.logo}`"
                  max-height="80px"
                  alt="logo"
                  contain
                  class="me-3 d-flex justify-center"
                ></v-img>
              </v-col>

              <v-col
                v-if="this.logo == ''"
                cols="12"
                sm="12"
              >
                <h2 class="text-2xl font-weight-semibold">
                  {{ imobiliaria }}
                </h2>
              </v-col>
            </v-row>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text v-if="this.vFormIdentificar">
          <p class="text-1xl font-weight-semibold text--primary mb-2 text-center">
            Alteração de e-mail
          </p>
          <p
            class="text-1xl font-weight-semibold text--primary mb-2 text-center"
            style="color: red !important;"
          >
            Por motivos de segurança, informe seu CPF/CNPJ e onde está situado um de seus imoveis.
          </p>
        </v-card-text>

        <!-- identificação form -->
        <v-card-text v-if="this.vFormIdentificar">
          <v-form @submit.stop.prevent="submit">
            <v-text-field
              v-model="cpf_cnpj"
              outlined
              label="CPF/CNPJ"
              placeholder="000.000.000-00"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-select
              v-model="obraSelecionada"
              class="mt-5"
              label="Selecione aqui"
              :items="this.obras"
              variant="outlined"
            ></v-select>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="tentarIdentificarDeOutroJeito()"
            >
              Tentar de outro jeito
            </v-btn>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="identificar()"
            >
              Identificar
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- title -->
        <v-card-text v-if="this.vFormIdentificarAlternativo">
          <p class="text-1xl font-weight-semibold text--primary mb-2 text-center">
            Alteração de e-mail
          </p>
          <p
            class="text-1xl font-weight-semibold text--primary mb-2 text-center"
            style="color: red !important;"
          >
            Por motivos de segurança, informe seu NOME COMPLETO, CPF ou CNPJ e sua DATA DE NASCIMENTO.
          </p>
        </v-card-text>

        <!-- identificação alternativa form -->
        <v-card-text v-if="this.vFormIdentificarAlternativo">
          <v-form @submit.stop.prevent="submit">
            <v-text-field
              v-model="nomeCompleto"
              outlined
              label="NOME COMPLETO"
              placeholder="JOÃO AUGUSTO RIBEIRO"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="cpf_cnpj"
              outlined
              label="CPF/CNPJ"
              placeholder="000.000.000-00"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="dataNascimento"
              v-mask="'##/##/####'"
              outlined
              label="DATA DE NASCIMENTO"
              placeholder="01/01/2000"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="tentarIdentificarDeOutroJeito()"
            >
              Tentar de outro jeito
            </v-btn>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="identificar()"
            >
              Identificar
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- title -->
        <v-card-text v-if="this.vFormCadastrar">
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">
            Cadastro identificado
          </p>
          <p
            class="text-2xl font-weight-semibold text--primary mb-2 text-center"
            style="color: red !important;"
          >
            Informe o novo email
          </p>
        </v-card-text>

        <!-- 2Fator cadastrar form -->
        <v-card-text v-if="this.vFormCadastrar">
          <v-form @submit.stop.prevent="submit">
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="joao@gmail.com"
              hide-details
              class="mb-3"
              type="text"
            ></v-text-field>

            <v-text-field
              v-model="emailconfirmation"
              outlined
              label="Confirme o novo e-mail"
              placeholder="joao@gmail.com"
              hide-details
              class="mb-3"
              type="text"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="alterarEmail()"
            >
              Confirmar
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- title -->
        <v-card-text v-if="this.vFormSucess">
          <p class="text-1xl font-weight-semibold text--primary mb-2 text-center">
            <v-icon
              x-large
              color="#1ad639"
              class="mb-2"
            >
              {{ icons.mdiCheckUnderlineCircle }}
            </v-icon>
          </p>

          <p class="text-1xl font-weight-semibold text--primary mb-2 text-center">
            E-mail alterado com sucesso
          </p>
          <p class="mb-2 text-center">
            Clique <router-link :to="{ name:'pages-register' }">
              aqui para fazer o cadastro
            </router-link>
          </p>
        </v-card-text>

        <!-- Make login  -->
        <v-card-text
          v-if="this.vFormCadastrar || this.vFormIdentificar"
          class="d-flex align-center justify-center flex-wrap mt-2"
        >
          <span class="me-2">
            Já tem uma conta ?
          </span>
          <router-link :to="{ name:'pages-login' }">
            Faça o login aqui
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <v-snackbar
      v-model="snackbar"
      :timeout="-1"
    >
      {{ this.toastText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiCheckUnderlineCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'

import moment from 'moment'
import UsuarioStore from '../../store/Usuario'
import Strings from '@/common/strings'

export default {
  data() {
    return {
      imobiliaria: process.env.VUE_APP_INCORPORATION_NAME,
      logo: process.env.VUE_APP_URL_LOGO,
      appId: process.env.VUE_APP_PROJECT_ID,
      obras: [],
      obraSelecionada: '',
    }
  },
  mounted() {
    this.obterContatos()
  },
  setup() {
    const cpf_cnpj = ref('')
    const nomeCompleto = ref('')
    const dataNascimento = ref('')
    const obra = ref('')
    const email = ref('')
    const emailconfirmation = ref('')
    const vFormIdentificar = ref(true)
    const vFormIdentificarAlternativo = ref(false)
    const vFormCadastrar = ref(false)
    const snackbar = ref(false)
    const toastText = ref('')
    const vFormSucess = ref(false)

    return {
      cpf_cnpj,
      nomeCompleto,
      dataNascimento,
      vFormIdentificar,
      vFormCadastrar,
      vFormIdentificarAlternativo,
      snackbar,
      toastText,
      vFormSucess,
      obra,
      emailconfirmation,
      email,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCheckUnderlineCircle,
      },
    }
  },
  methods: {
    obterContatos() {
      this.$loading(true)
      UsuarioStore.obterContatosPorAppI(Strings.imobiliariaId, Strings.project_id).then(result => {
        result.data.forEach(element => {
          this.obras.push(element.Descr_obr)
        })

        this.$loading(false)
      }).catch(error => {
        console.log(error)
        this.vFormIdentificar = true
        this.toastText = error
        this.snackbar = true
        this.$loading(false)
      })
    },
    tentarIdentificarDeOutroJeito() {
      if (this.vFormIdentificar) {
        this.vFormIdentificar = false
        this.vFormIdentificarAlternativo = true
      } else {
        this.vFormIdentificar = true
        this.vFormIdentificarAlternativo = false
      }
    },
    identificar() {
      if (this.cpf_cnpj === '') {
        this.toastText = 'Informe o CPF/CNPJ'
        this.snackbar = true

        return
      }

      const cpf_cnpj_without_chars = this.cpf_cnpj.replace(/[^\d]+/g, '')
      this.cpf_cnpj = cpf_cnpj_without_chars
      if (cpf_cnpj_without_chars == '') {
        this.toastText = 'Informe corretamente o CPF/CNPJ'
        this.snackbar = true

        return
      }

      if (this.vFormIdentificar === true) {
        if (this.obraSelecionada === '') {
          this.toastText = 'Informe o empreedimento corretamente'
          this.snackbar = true

          return
        }

        this.vFormIdentificar = false
        this.vFormIdentificarAlternativo = false

        const payload = {
          cpf_cnpj: cpf_cnpj_without_chars,
          empreendimento: this.obraSelecionada,
        }

        this.$loading(true)
        UsuarioStore.identificarCadastro(payload, Strings.imobiliariaId).then(() => {
          this.vFormCadastrar = true
          this.$loading(false)
        }).catch(error => {
          console.log(error)
          this.vFormIdentificar = true
          this.toastText = error.response.data
          this.snackbar = true
          this.$loading(false)
        })
      } else if (this.vFormIdentificarAlternativo) {
        if (this.nomeCompleto === '') {
          this.toastText = 'Informe o nome completo corretamente'
          this.snackbar = true

          return
        }

        if (this.dataNascimento === '') {
          this.toastText = 'Informe a Data de Nascimento corretamente'
          this.snackbar = true

          return
        }

        this.vFormIdentificar = false
        this.vFormIdentificarAlternativo = false

        const payload = {
          nomeCompleto: this.nomeCompleto,
          cpf_cnpj: cpf_cnpj_without_chars,
          dataNascimento: moment.utc(String(this.dataNascimento), 'DD/MM/YYYY').format('YYYY-MM-DD'),
        }

        console.log(payload)

        this.$loading(true)
        UsuarioStore.identificarCadastroAlternativo(payload, Strings.imobiliariaId).then(() => {
          this.vFormCadastrar = true
          this.$loading(false)
        }).catch(error => {
          console.log(error)
          this.vFormIdentificarAlternativo = true
          this.toastText = error.response.data
          this.snackbar = true
          this.$loading(false)
        })
      }
    },
    alterarEmail() {
      if (this.email == '' || this.emailconfirmation == '') {
        this.toastText = 'Informe o e-mail e confirme corretamente'
        this.snackbar = true

        return
      }

      if (this.email.trim() != this.emailconfirmation.trim()) {
        this.toastText = 'Os emails não conferem, informe-os corretamente'
        this.snackbar = true

        return
      }

      this.$loading(true)

      const payload = {
        cpf_cnpj: this.cpf_cnpj,
        email: this.email,
      }

      UsuarioStore.alterarEmailPessoa(payload, Strings.imobiliariaId).then(() => {
        this.vFormCadastrar = false
        this.vFormSucess = true
        this.$loading(false)
      }).catch(error => {
        console.log(error)
        this.toastText = error.response.data
        this.snackbar = true
        this.$loading(false)
      })
    },
  },
}
</script>

  <style lang="scss">
  @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
  </style>
